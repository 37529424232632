import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { HeadComponent } from "../components/HeadComponent"

import {content, maitriseOuvrage} from './references.module.scss'
import CoverLayout from "../components/CoverLayout"
import LogosReferences from "../components/LogosReferences"

const ReferencesPage = ({ data }) => {

  const coverImage = {
    image: data.image,
    alt: "© - Milomon - https://www.instagram.com/milomon/"
  }

  return (
    <Layout chClassName={content}>
      <HeadComponent title="Urbanisme & Cartographie" />
      <CoverLayout coverImage={coverImage}>
        <h3>Références</h3>
        <p>
          <ul>
            <li>
              <span className={maitriseOuvrage}>
                ADEME, 2022
              </span>{" "}
              – Elaboration d'une méthodologie et d'un modèle de saisie de données pour les Inventaires Historiques 
              Urbains (IHU)  - ADEME. EACM,  Montachet cotraitants.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Normandie, 2022
              </span>{" "}
              – Mission d'accompagnement pour l'élaboration d'une stratégie foncière  - Communauté
              Urbaine de Caen La Mer. Avant-Projet, CODRA, Métropolis, Lichen cotraitants.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                La FAB, 2022
              </span>{" "}
              – AMO études foncières  - La FAB - Bordeaux Métropole. Avant-Projet et SEGAT cotraitants.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                Douaisis Agglomération, 2022
              </span>{" "}
              – Volet Foncier du PLH - Communauté
              d'Agglomération du Douaisis. Ville et Habitat cotraitant.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Normandie, 2021
              </span>{" "}
              –  Mission d’accompagnement pour l’élaboration d’une stratégie foncière - Communauté
              d'Agglomération Seine-Eure (CASE). Avant-Projet cotraitant.
            </li>
            <li>
              <span className={maitriseOuvrage}>
              Communauté d’Agglomération du Pays de Meaux, 2021
              </span>{" "}
              – Réalisation du volet foncier du 3ème Programme Local de l’habitat Communauté d’Agglomération du Pays de Meaux. 
              Avec Avant-Projet/Territoires Autrement cotraitants.
            </li>
            <li>
              <span className={maitriseOuvrage}>
              Communauté d’Agglomération Grand Paris Sud, 2021
              </span>{" "}
              – Analyse de la consommation foncière dans le cadre du SCoT.
              Avant-Projet cotraitant.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Grand Est, 2021
              </span>{" "}
              – Etude de stratégie foncière pour la Communauté de Communes du Pays d’Orne et Moselle. 
              Avant-Projet cotraitant.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Nouvelle Aquitaine, 2019
              </span>{" "}
              – Diagnostic foncier du territoire communautaire - Communauté
              Urbaine Limoges Métropole. Avant-Projet et CODRA cotraitants.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Normandie, 2019
              </span>{" "}
              – Mission d’élaboration d’un référentiel foncier sur le site
              stratégique du Val d’Hazey-Gaillon – Communauté de Communes Eure
              Madrie Seine (27). Avec Avant-Projet et EACM.
            </li>
            <li>
              <span className={maitriseOuvrage}>EPA SENART, 2019</span> –
              Etude foncière avec constitution d’une base de données pour le
              renouvellement de la zone d’activités portuaire du quartier Saint
              Louis à Dammarie-les-Lys. Mandataire, avec Avant-Projet et EACM
              cotraitants.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Normandie, 2018
              </span>{" "}
              – Mission d’accompagnement pour l’élaboration d’une stratégie
              foncière de la Commune nouvelle de Vire Normandie. Avec
              Avant-Projet, mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Normandie, 2018
              </span>{" "}
              – Mission d’accompagnement pour l’élaboration d’une stratégie
              foncière de la Communauté de Communes du Pays de Falaise. Avec
              Avant-Projet, mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Nord Pas de Calais, 2018
              </span>{" "}
              – Réalisation d'une étude pour l'identification et la mobilisation
              du gisement foncier en renouvellement urbain pour la commune de
              Lille. Avec Avant-Projet, mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                EPF Nord Pas de Calais, 2018
              </span>{" "}
              – Réalisation d'une étude d’identification du gisement foncier en
              renouvellement urbain pour la communauté d’Agglomération Hénin
              Carvin. Avec Avant-Projet, mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                Communauté d’Agglomération Grand Paris Sud, 2018
              </span>{" "}
              – Assistance à maîtrise d’ouvrage pour la réalisation d’une étude
              foncière approfondie. Avec Avant-Projet, mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                Communauté d’Agglomération Seine Eure, 2018
              </span>{" "}
              – Réalisation du volet foncier du PLUI-H. Avec Ar-Themis,
              mandataire.{" "}
            </li>
            <li>
              <span className={maitriseOuvrage}>
                Maremne Adour Côte Sud, 2017
              </span>{" "}
              – Définition d’une stratégie foncière en faveur de logement dans
              le cadre de l’élaboration du PLUi et PLHi, 23 communes, création
              d’un outil SIG de suivi. Avec Avant-Projet, mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                Communauté d’Agglomération de Blois Agglopolys, 2017{" "}
              </span>{" "}
              – Réalisation du volet foncier du PLUI-HD. Avec Ar-Themis,
              mandataire.
            </li>
            <li>
              <span className={maitriseOuvrage}>
                Grand Paris Seine Et Oise, 2016
              </span>{" "}
              – Elaboration du référentiel foncier sur les 73 communes de
              l’agglomération, création d’un outil SIG de suivi. Avec
              Avant-Projet, mandataire.
            </li>
          </ul>
        </p>
      </CoverLayout>
      <LogosReferences/>
    </Layout>
  )}

export const pageQuery = graphql`{
  image: file(relativePath: {eq: "milomon/BcRkFwJh99I.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 480
        tracedSVGOptions: {background: "#fff", color: "#007FE6"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`



export default ReferencesPage
